/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://5ptyq4777bh25i37vwnpwsabj4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-mocuufnv5rguplldjbfiikkvjm",
    "aws_cognito_identity_pool_id": "us-east-2:b285add6-8edd-4688-813b-38146ca77436",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_trPyxS2OI",
    "aws_user_pools_web_client_id": "3bbgs0b868gukui0v9jdtg8lrf",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "journals",
            "region": "us-east-2"
        },
        {
            "tableName": "PullJournalsTable",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
